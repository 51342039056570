import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, NgForm, NgModel } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserIdleModule } from 'angular-user-idle';

import { LoaderInterceptor } from './interceptors/loader.interceptors';

import { AppComponent } from './app.component';
import { NavComponent } from './traits/nav/nav.component';
import { FootComponent } from './traits/foot/foot.component';
import { LoaderComponent } from './traits/loader/loader.component';

import { DashboardComponent } from './views/dashboard/dashboard.component';
import { FinanceDaybookComponent } from './views/finance/finance-daybook/finance-daybook.component';
import { FinancePaymentComponent } from './views/finance/finance-payment/finance-payment.component';
import { FinanceSummaryComponent } from './views/finance/finance-summary/finance-summary.component';
import { FinanceReportComponent } from './views/finance/finance-report/finance-report.component';
import { SetupSequenceComponent } from './views/setup/setup-sequence/setup-sequence.component';
import { SetupUserComponent } from './views/setup/setup-user/setup-user.component';
import { SetupSmslogsComponent } from './views/setup/setup-smslogs/setup-smslogs.component';
import { RouterModule, RouterOutlet, Routes } from '@angular/router';
import { appRoute } from './router';
import { LoginComponent } from './views/auth/login/login.component';
import { SetupSmsComponent } from './views/setup/setup-sms/setup-sms.component';

import { SigninService } from './services/signin.service';
import { SetupService } from './services/setup.service';
import { AuthGuard } from './guards/auth.guard';
import { ExitpageGuard } from './guards/exitpage.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { NotfoundComponent } from './views/error/notfound/notfound.component';
import { SetupTransitComponent } from './views/setup/setup-transit/setup-transit.component';
import { InventoryService } from './services/inventory.service';
import { TellerService } from './services/teller.service';
import { SetupLocationComponent } from './views/setup/setup-location/setup-location.component';
import { ForgetPasswordComponent } from './views/auth/forget-password/forget-password.component';
import { SetupTellerComponent } from './views/setup/setup-teller/setup-teller.component';
import { CustomerComponent } from './views/customer/customer.component';
import { AddCustomerComponent } from './views/customer/add-customer/add-customer.component';
import { SetupCompanyComponent } from './views/setup/setup-company/setup-company.component';
import { AlertsComponent } from './views/alerts/alerts.component';
import { TellerPaymentComponent } from './views/teller/teller-payment/teller-payment.component';
import { SetupAccountComponent } from './views/setup/setup-account/setup-account.component';
import { AccountsComponent } from './views/accounts/accounts.component';
import { AddAccountComponent } from './views/accounts/add-account/add-account.component';
import { AccountApprovalComponent } from './views/accounts/account-approval/account-approval.component';
import { ManagerService } from './services/manager.service';
import { ViewCustomerComponent } from './views/customer/view-customer/view-customer.component';
import { SetupBranchComponent } from './views/setup/setup-branch/setup-branch.component';
import { SetupSessionComponent } from './views/setup/setup-session/setup-session.component';
import { AccountStatusComponent } from './views/accounts/account-status/account-status.component';
import {HashLocationStrategy, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { AccountChequeComponent } from './views/accounts/account-cheque/account-cheque.component';
import { TellerBatchComponent } from './views/teller/teller-batch/teller-batch.component';
import { MigrationsComponent } from './views/migrations/migrations.component';
import { RelationOfficersComponent } from './views/relation-officers/relation-officers.component';
import { AddRelationOfficerComponent } from './views/relation-officers/add-relation-officer/add-relation-officer.component';
import { GroupsComponent } from './views/groups/groups.component';
import { AddGroupComponent } from './views/groups/add-group/add-group.component';
import { ViewGroupComponent } from './views/groups/view-group/view-group.component';
import { AgentsComponent } from './views/agents/agents.component';
import { AddAgentComponent } from './views/agents/add-agent/add-agent.component';
import { CustomerReportComponent } from './views/customer/customer-report/customer-report.component';
import { ChangeRelationOfficerComponent } from './views/relation-officers/change-relation-officer/change-relation-officer.component';
import { AccountReportComponent } from './views/accounts/account-report/account-report.component';
import { GroupReportComponent } from './views/groups/group-report/group-report.component';
import { PaginationComponent } from './traits/pagination/pagination.component';
import { ViewAgentComponent } from './views/agents/view-agent/view-agent.component';
import { SetupChargeComponent } from './views/setup/setup-charge/setup-charge.component';
import { ViewRelationOfficerComponent } from './views/relation-officers/view-relation-officer/view-relation-officer.component';
import { ApprovalsComponent } from './views/approvals/approvals.component';
import { LockComponent } from './views/auth/lock/lock.component';
import { AccountGuard } from './guards/account.guard';
import { AdminGuard } from './guards/admin.guard';
import { SupportGuard } from './guards/support.guard';
import { ManagerGuard } from './guards/manager.guard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SetupCountryComponent } from './views/setup/setup-country/setup-country.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return JSON.parse(localStorage.getItem('Token'));
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FootComponent,
    PaginationComponent,
    DashboardComponent,
    FinanceDaybookComponent,
    FinancePaymentComponent,
    FinanceSummaryComponent,
    FinanceReportComponent,
    SetupSequenceComponent,
    SetupUserComponent,
    SetupSmslogsComponent,
    LoginComponent,
    SetupSmsComponent,
    NotfoundComponent,
    SetupTransitComponent,
    SetupLocationComponent,
    ForgetPasswordComponent,
    SetupTellerComponent,
    CustomerComponent,
    AddCustomerComponent,
    SetupCompanyComponent,
    AlertsComponent,
    TellerPaymentComponent,
    SetupAccountComponent,
    AccountsComponent,
    AddAccountComponent,
    AccountApprovalComponent,
    SetupChargeComponent,
    ViewCustomerComponent,
    SetupBranchComponent,
    SetupSessionComponent,
    AccountStatusComponent,
    AccountChequeComponent,
    LoaderComponent,
    TellerBatchComponent,
    MigrationsComponent,
    RelationOfficersComponent,
    AddRelationOfficerComponent,
    GroupsComponent,
    AddGroupComponent,
    ViewGroupComponent,
    AgentsComponent,
    AddAgentComponent,
    CustomerReportComponent,
    ChangeRelationOfficerComponent,
    AccountReportComponent,
    GroupReportComponent,
    ViewAgentComponent,
    ViewRelationOfficerComponent,
    ApprovalsComponent,
    LockComponent,
    SetupCountryComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    NgSelectModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      }
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      progressBar: true
    }),
    UserIdleModule.forRoot({idle: 600, timeout: 300, ping: 600 }),
    RouterModule.forRoot(appRoute)
  ],
  providers: [ 
    AuthGuard, ManagerGuard, AccountGuard, SupportGuard, AdminGuard, 
    ExitpageGuard, ManagerService, SigninService, SetupService, InventoryService, TellerService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    // { provide: LocationStrategy, useClass: HashLocationStrategy } 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
